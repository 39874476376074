<template>
    <div class="flex-row align-items-center">
        <CRow class="justify-content-center headimg mb-5">
            <CCol md="12" class="p-0 ">
                <div style="background-image: url('img/Dashboard.png'); background-position: 50% 50%; background-size: cover; height: 200px;">
                    <div class="middle-center text-center text-white">
                        <h2 class=""><b>Laporan <span class="text-success">Realisasi</span></b></h2>
                    </div>
                </div>
            </CCol>
        </CRow>
        <div class="container">
            <CRow class="justify-content-center">
                <CCol md="12">
                    <CCard class="top-radius-15">
                        <CCardHeader class="top-radius-15 bg-success text-white">
                            <h4 class="my-0 text-center"><b>Data Realisasi</b></h4>
                        </CCardHeader>
                        <CCardBody>
                            
                            <div class="row">
                                <div class="col-md-9">
                                    <div class="row">
                                        <div class="col-md">
                                            <div class="form-group">
                                                <b for="">Tgl Dari</b>
                                                <vuejs-datepicker input-class="form-control no-readonly" v-model="start" format="dd MMM yyyy"></vuejs-datepicker>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div class="form-group">
                                                <b for="">Tgl Sampai</b> 
                                                <vuejs-datepicker input-class="form-control no-readonly" v-model="end" format="dd MMM yyyy"></vuejs-datepicker>
                                            </div>
                                        </div>
                                        
                                        
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <b for="">Cari</b>
                                                <input type="text" class="form-control" v-model="search">
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="row row-condensed" style="margin-top: 23px;">
                                        <div class="col-md p-0">
                                            <div class="btn btn-sm btn-primary" v-on:click="filterData"><i class="fa fa-filter"></i> Filter</div>
                                        </div>
                                        <div class="col-md p-0">
                                            <div class="btn btn-sm btn-success" v-on:click="getExcel"><i class="fa fa-download"></i> Excel</div>
                                        </div>
                                        <div class="col-md-7 text-right">
                                            <template>
                                                <router-link v-if="!idpermohonan" :to="{name: 'AddLaporanPemohonRealisasi'}" class="btn btn-info btn-sm"><i class="fa fa-plus"></i> Lapor Realisasi</router-link>
                                                <router-link v-else :to="{name: 'AddLaporanRealisasiByPermohonan', params: { idpermohonan: idpermohonan }}" class="btn btn-info btn-sm"><i class="fa fa-plus"></i> Lapor Realisasi</router-link>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <CRow>
                                <CCol sm="12">
                                    <CRow>
                                        <CCol sm="12">
                                            <table class="table table-sm table-bordered">
                                                <thead class="bg-dark text-white text-center">
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Permohonan</th>
                                                        <th>Nomor Realisasi</th>
                                                        <th>Tanggal Pengajuan</th>
                                                        <th>Aksi</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <template>
                                                    <tbody v-if="reslistrealisasi.length != 0">
                                                        <template>
                                                            <tr v-if="loadingTable">
                                                                <td colspan="6"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
                                                            </tr>
                                                            <tr v-for="(listrealisasi, key_realisasi) in reslistrealisasi" v-else v-bind:key="key_realisasi">
                                                                <td class="align-middle text-center">
                                                                    <!-- {{key_realisasi+1}} -->
                                                                    <template>
                                                                        <div v-if="key_realisasi+1 == 10">
                                                                            {{curent_page}}0
                                                                        </div>
                                                                        <div v-else>
                                                                            <template>
                                                                                <span v-if="curent_page-1 != 0">
                                                                                    {{curent_page-1}}{{key_realisasi+1}}
                                                                                </span>
                                                                                <span v-else>
                                                                                    {{key_realisasi+1}}
                                                                                </span>
                                                                            </template>
                                                                        </div>
                                                                    </template>
                                                                </td>
                                                                <td>
                                                                    <table class="table table-sm table-borderless">
                                                                        <tr>
                                                                            <td style="width: 150px">Kategori Permohonan</td>
                                                                            <td style="width: 15px">:</td>
                                                                            <th>{{listrealisasi.prs_kategori}}</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Kode Permohonan</td>
                                                                            <td>:</td>
                                                                            <th>{{(listrealisasi.kode_permohonan)?listrealisasi.kode_permohonan:"Data Tidak Tersedia"}} </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Nomor SIP</td>
                                                                            <td>:</td>
                                                                            <th>{{listrealisasi.prs_nomor_sk}}</th>
                                                                        </tr>


                                                                    </table>
                                                                    
                                                                    
                                                                </td>
                                                                <td class="align-middle text-center">{{listrealisasi.prs_nomor}}</td>
                                                                <td class="align-middle text-center">{{listrealisasi.prs_tanggal}}</td>
                                                                <td class="align-middle text-center">
                                                                    <router-link :to="{name: 'DetailRealisasiPemohon', params: { idrealisasi: listrealisasi.realisasi_id }}" class="btn btn-info btn-sm btn-block"><i class="fa fa-eye"></i> Detail</router-link>
                                                                    <div class="btn btn-success btn-block btn-sm mt-1" v-on:click="modalRealisasi(listrealisasi.id)"><i class="fa fa-eye"></i> Lihat Data benih</div>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <template>
                                                            <tr v-if="loadingTable">
                                                                <td colspan="6"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
                                                            </tr>
                                                            <tr v-else>
                                                                <td colspan="7">Data tidak tersedia</td>
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                </template>
                                            </table>
                                            <nav aria-label="pagination">
                                                <ul class="pagination justify-content-start"> 
                                                    <li v-for="(page, key_page) in paging" v-bind:class="{ 'active': page.active }" class="page-item" v-bind:key="key_page">
                                                        
                                                        <div v-on:click="toPage(page.url)" class="page-link c-page-link-number">
                                                            <div v-html="page.label"></div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                            <br />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
        <template>
            <div>
            <CModal
                title="Detail Realisasi"
                color="btn-primary"
                :show.sync="modal_detailrealisasi"
                size="xl"
            >
            <table class="table table-bordered table-sm w-100" id="table-benih">
                <thead class="bg-sapphire">
                    <tr>
                        <th style="width: 15px">No</th>
                        <th>Jenis Tanaman</th>
                        <th>Nama Varietas</th>
                        <th>Bentuk Benih</th>
                        <th>Jumlah</th>
                        <th>Jumlah Realisasi</th>
                        <th>Jumlah Sisa</th>
                        <th>Satuan</th>
                        <th>Nilai</th>
                        <th>Kode HS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value_benih, index_benih) in detailDataRealisasi.permohonan_realisasi_benih" :key="index_benih">
                        <td>{{index_benih+1}}</td>
                        <td>{{value_benih.tanaman.t_nama}} - {{value_benih.prsb_nama_latin}}</td>
                        
                        <td>{{value_benih.prsb_varietas}}</td>
                        <td>{{value_benih.bentuk_benih.bb_nama}}</td>
                        <td>{{value_benih.prsb_jumlah}}</td>
                        <td>{{value_benih.prsb_jumlah_realisasi}}</td>
                        <td>{{value_benih.prsb_jumlah_sisa}}</td>
                        <td>{{value_benih.satuan.s_nama}}</td>
                        <td>{{value_benih.prsb_nilai_total}}</td>
                        <td>{{value_benih.prsb_kode_hs}}</td>
                    </tr>
                </tbody>
            </table>
            
            <template #footer>
                <CButton @click="modal_detailrealisasi = false" color="secondary">Tutup</CButton>
            </template>
            </CModal>
            </div>
        </template>
    </div>
</template> 
<script>
    const datalaporan = [];
    export default {
        name: "AddLaporanPemohonRealisasi",
        components: {
            vuejsDatepicker
        },
        data() {
            return {
                reslistrealisasi: datalaporan,
                idpermohonan: this.$route.params?.idpermohonan,
                paging: [],
                show_data_to: "",
                show_data_from: "",
                total_data: "",
                curent_page: '',
                loadingTable: true,
                session_data: JSON.parse(this.session),
                is_pemasukan: "",
                start: "",
                end: "",
                status: "",
                excel_url:"",
                search: "",
                filter_status: [
                    {
                        value: "proses",
                        label: "Proses Verifikasi",
                    },
                    {
                        value: "menunggu perbaikan",
                        label: "Menunggu Perbaikan",
                    },
                    {
                        value: "selesai",
                        label: "Selesai",
                    },
                    {
                        value: "dibatalkan",
                        label: "Dibatalkan",
                    },
                ],
                prs_kategori: [],
                filter_module: [
                    {
                        value: "Hortikultura",
                        label: "Hortikultura",
                    },
                    {
                        value: "Sumber Daya Genetik",
                        label: "Sumber Daya Genetik",
                    },
                    {
                        value: "Tanaman Pangan",
                        label: "Tanaman Pangan",
                    },
                    {
                        value: "Perkebunan",
                        label: "Perkebunan",
                    },
        {
          value: "Hijauan Pakan Ternak",
          label: "Hijauan Pakan Ternak",
        },
                ],
                filter_tipe: [
                    {
                        value: "1",
                        label: "Pemasukkan Benih Tanaman",
                    },
                    {
                        value: "0",
                        label: "Pengeluaran Benih Tanaman",
                    },
                ],
                modal_detailrealisasi: false,
                detailDataRealisasi: [],
            };
        },
        methods: {
            modalRealisasi(idrealisasi){
				var detailData = this.reslistrealisasi.find(detailDatas => {
                    if (detailDatas.id === idrealisasi) {
                        return detailDatas
                    }
                })
				this.modal_detailrealisasi = true;
                this.detailDataRealisasi = detailData;
			},
            toPage(url) {
                this.loadingTable = true;
                // var numpage = url.replace(this.apiLink + "api/laporan/permohonan_benih?page=", "");
                let session_data = JSON.parse(this.session);
                axios
                .get(url, {
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_realisasi = response.data;
                    var res_realisasi_data = res_realisasi.data;
                    // console.log(res_realisasi_data);
                    if (res_realisasi.meta.code != "200") {
                        Swal.fire({
                            icon: "error", 
                            title: "Oops...",
                            text: res_realisasi.data.message,
                        });
                    } else {
                        // let raw_realisasi = [];
                        // $.each(res_realisasi_data, function(index_realisasi, val_realisasi) {
                        //      $.each(val_realisasi.permohonan_realisasi_benih, function(index_benih, val_benih) {
                        //         val_benih.realisasi = val_realisasi;
                        //         raw_realisasi.push(val_benih);
                        //      });
                        // });
                        // console.log(raw_realisasi);
                        this.loadingTable = false;
                        this.reslistrealisasi = res_realisasi_data.data.data;
                        this.excel_url = "https://simpel2.pertanian.go.id/endpoint/public/api/excel_realisasi?nib="+session_data.nib;
                        this.paging = res_realisasi_data.data.links;
                        console.log(this.reslistrealisasi);
                        // this.paging = res_realisasi_data.data.links;
                        // this.show_data_from = res_realisasi_data.data.from;
                        // this.show_data_to = res_realisasi_data.data.to;
                        // this.total_data = res_realisasi_data.data.total;
                        this.curent_page = res_realisasi_data.data.current_page;
                    }
                });
            },
            filterData() {
                this.loadingTable = true;
                // var numpage = url.replace(this.apiLink + "api/laporan/permohonan_benih?page=", "");
                let session_data = JSON.parse(this.session);
                axios
                .get(this.apiLink + "api/permohonan_realisasi/permohonan_all", {
                    params: {
                        nib: session_data.nib,
                        start: this.start,
                        end: this.end,
                        prs_kategori: this.prs_kategori,
                        search: this.search
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_realisasi = response.data;
                    var res_realisasi_data = res_realisasi.data;
                    // console.log(res_realisasi_data);
                    if (res_realisasi.meta.code != "200") {
                        Swal.fire({
                            icon: "error", 
                            title: "Oops...",
                            text: res_realisasi.data.message,
                        });
                    } else {
                        // let raw_realisasi = [];
                        // $.each(res_realisasi_data, function(index_realisasi, val_realisasi) {
                        //      $.each(val_realisasi.permohonan_realisasi_benih, function(index_benih, val_benih) {
                        //         val_benih.realisasi = val_realisasi;
                        //         raw_realisasi.push(val_benih);
                        //      });
                        // });
                        // console.log(raw_realisasi);
                        this.loadingTable = false;
                        this.reslistrealisasi = res_realisasi_data.data.data;
                        this.excel_url = "https://simpel2.pertanian.go.id/endpoint/public/api/excel_realisasi?nib="+session_data.nib;
                        this.paging = res_realisasi_data.data.links;
                        console.log(this.reslistrealisasi);
                        // this.paging = res_realisasi_data.data.links;
                        // this.show_data_from = res_realisasi_data.data.from;
                        // this.show_data_to = res_realisasi_data.data.to;
                        // this.total_data = res_realisasi_data.data.total;
                        this.curent_page = res_realisasi_data.data.current_page;
                    }
                });
            },
            getExcel(){
                var win = window.open(this.excel_url, '_blank');
                if (win) {
                    //Browser has allowed it to be opened
                    win.focus();
                } else {
                    //Browser has blocked it
                    alert('Please allow popups for this website');
                }
            }
        },
        beforeCreate() {
            // let self = this;
            let session_data = JSON.parse(this.session);
            axios
                .get(this.apiLink + "api/permohonan_realisasi/permohonan_all", {
                    params: {
                        nib: session_data.nib,
                        permohonan_id: this.$route.params?.idpermohonan
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_realisasi = response.data;
                    var res_realisasi_data = res_realisasi.data;
                    // console.log(res_realisasi_data);
                    if (res_realisasi.meta.code != "200") {
                        Swal.fire({
                            icon: "error", 
                            title: "Oops...",
                            text: res_realisasi.data.message,
                        });
                    } else {
                        // let raw_realisasi = [];
                        // $.each(res_realisasi_data, function(index_realisasi, val_realisasi) {
                        //      $.each(val_realisasi.permohonan_realisasi_benih, function(index_benih, val_benih) {
                        //         val_benih.realisasi = val_realisasi;
                        //         raw_realisasi.push(val_benih);
                        //      });
                        // });
                        // console.log(raw_realisasi);
                        this.loadingTable = false;
                        this.reslistrealisasi = res_realisasi_data.data.data;
                        this.excel_url = "https://simpel2.pertanian.go.id/endpoint/public/api/excel_realisasi?nib="+session_data.nib;
                        this.paging = res_realisasi_data.data.links;
                        console.log(this.reslistrealisasi);
                        // this.paging = res_realisasi_data.data.links;
                        // this.show_data_from = res_realisasi_data.data.from;
                        // this.show_data_to = res_realisasi_data.data.to;
                        // this.total_data = res_realisasi_data.data.total;
                        this.curent_page = res_realisasi_data.data.current_page;
                    }
                });
        },
        mounted() {
            $('.no-readonly').prop('readonly', false);
        }
    };
</script>